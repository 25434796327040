<template>
  <div v-if="adStatus == 'shown' || 'failed'">
    <v-row
      dense
      class="text-center justify-center"
      v-if="mocktest && mocktest.id"
    >
      <v-col cols="12" md="8">
        <v-card class="mb-2">
          <v-card-title class="blue accent-3 white--text">
            <v-btn dark icon @click="$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon></v-btn
            >
            <v-spacer></v-spacer>
            <span>
              {{ mocktest.title }}
            </span>
          </v-card-title>
        </v-card>
        <v-card class="mb-2">
          <v-card-actions>
            <v-icon left>mdi-format-list-numbered</v-icon>
            {{ $lang("Questions") }}
            <v-spacer></v-spacer>
            {{
              mocktest.segments.reduce((pre, cur) => {
                return pre + cur.questions.length;
              }, 0)
            }}
          </v-card-actions>

          <v-card-actions>
            <v-icon left>mdi-clock</v-icon>
            {{ $lang("Duration") }}
            <v-spacer></v-spacer>
            {{ calculateDuration(mocktest) }}
          </v-card-actions>
          <v-card-actions>
            <v-icon left>mdi-account-circle</v-icon>
            {{ $lang("Created_By") }}
            <v-spacer></v-spacer>
            <span
              style="margin-left: -0.4rem"
              text
              @click.stop="
                $router.push({
                  name: 'profile',
                  params: {
                    id: mocktest.user
                      ? mocktest.user.username
                      : mocktest.user_id,
                  },
                })
              "
            >
              {{
                mocktest.user
                  ? mocktest.user.display_name.toUpperCase()
                  : mocktest.user_id
              }}
            </span>
          </v-card-actions>
          <v-card-actions v-if="mocktest.showDate">
            {{ $lang("Date") }}
            <v-spacer></v-spacer>
            {{ mocktest.updated_at | formatDateOnly }}
          </v-card-actions>
        </v-card>
        <v-card v-if="mocktest.description && mocktest.description.length > 10">
          <v-card-text class="title"> {{ $lang("DIRECTIONS") }}: </v-card-text>
          <v-divider></v-divider>
          <v-card-text v-html="mocktest.description" />
        </v-card>
        <v-card class="mt-2">
          <v-card-text class="text-left" v-html="directions"> </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-actions>
            <select-test-language />
          </v-card-actions>
        </v-card>
        <v-card class="mt-2" ripple>
          <v-card-actions>
            <v-checkbox v-model="agree" />
            <v-card-text
              @click="agree = !agree"
              class="text-left"
              v-html="disclaimer"
              style="cursor: pointer"
            >
            </v-card-text>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              :disabled="loading || !agree"
              :loading="loading"
              @click="
                $router.push({
                  name: 'exams-mocktest',
                  params: { id: mocktest.id },
                })
              "
              :class="mainBtn.color + ' darken-3'"
              text
              block
              dark
            >
              <span v-if="!agree">
                {{
                  isHindi
                    ? "पहले ऊपर दी हुई शर्तों को स्वीकार करें"
                    : "Accept the terms above."
                }}
              </span>
              <span v-else>{{ $lang(mainBtn.text) }}</span>
              <span v-if="mainBtn.time && agree">
                ({{ mainBtn.time.toString().fancyTime() }})
              </span>
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import SelectTestLanguage from "../modules/SelectTestLanguage";
import moment from "@/plugins/moment";

import { Device } from "@capacitor/device";
import { interstitial } from "@/plugins/admob/interstitial";

export default {
  name: "exams-mocktest-preview",
  components: { SelectTestLanguage },
  data() {
    return {
      mainBtn: {
        color: "green",
        text: "Start",
        time: null,
      },
      internal_directions: null,
      internal_disclaimer: null,
      agree: false,
      pagination: {
        lastPage: null,
        page: null,
        perPage: null,
        total: null,
      },
      mocktestUser: {},
      adStatus: null,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      getMocktestsById: "app/exams/getMocktestsById",
      mocktests: "app/exams/mocktests",
      attempt: "app/exams/attempt",
    }),
    disclaimer() {
      if (!this.internal_disclaimer || !this.mocktest) {
        return null;
      }
      let string = this.isHindi
        ? this.internal_disclaimer.value_hi
        : this.internal_disclaimer.value;

      let find = ["\n"];

      let replace = ["<br/>"];
      return string.replaceArray(find, replace);
    },
    directions() {
      if (!this.internal_directions || !this.mocktest) {
        return null;
      }
      let string = this.isHindi
        ? this.internal_directions.value_hi
        : this.internal_directions.value;

      let find = [
        "{{sections}}",
        "{{questions}}",
        "{{options}}",
        "{{duration}}",
        "{{positive}}",
        "{{negative}}",
        "\n",
      ];
      console.log("this.mocktest", this.mocktest);
      let replace = [
        this.mocktest.segments.length,
        this.mocktest.questions.length,
        "4",
        this.mocktest.duration.toString().fancyTime(),
        this.mocktest.points_correct,
        this.mocktest.points_incorrect,
        "<br/>",
      ];
      return string.replaceArray(find, replace);
    },
    mocktest() {
      return this.mocktests.find((el) => el.id == this.$route.params.id);
    },
  },
  methods: {
    btnCalc() {
      if (this.mocktest && this.mocktest.elapsed) {
        let time = this.mocktest.duration - this.mocktest.elapsed;
        this.mainBtn = {
          color: "orange",
          text: "Start",
          time: time,
        };
      } else {
        this.mainBtn = {
          color: "green",
          text: "Start",
          time: 0,
        };
      }
    },
    calculateDuration(mocktest) {
      let duration = 0;
      if (mocktest.duration > 0) {
        duration = mocktest.duration;
      } else {
        duration = mocktest.segments.reduce((pre, cur) => {
          return pre + cur.duration;
        }, 0);
      }
      return duration.toString().fancyTime();
    },
    axiosMocktest() {
      return axios
        .get(`prep/mocktests/public/${this.$route.params.id}/questions`)
        .then((res) => {
          this.$store.dispatch("app/exams/UpdateMocktests", res.data.data);
        })
        .catch((err) => {
          if (err.response && err.response.status) {
            if (err.response.status == 401) {
              console.error("Error", err.response.data.message);
              this.$router.replace({
                name: "exams-mocktest-unlock",
                params: { id: this.$route.params.id },
              });
            } else {
              this.$router.go(-1);
            }
          } else {
            this.$router.go(-1);
          }
        });
    },

    axiosGetMeta() {
      return axios
        .get(`globals/mocktest_directions`)
        .then((res) => {
          console.log(res);
          this.internal_directions = res.data;
          return axios.get(`globals/mocktest_disclaimer`).then((res) => {
            this.internal_disclaimer = res.data;
            return Promise.resolve(res.data);
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          // bus.$emit("hideAppBar");
          // bus.$emit("hideBottomNav");
        });
    },
    saveAttemptRedirect(res) {
      return this.$store
        .dispatch("app/exams/SaveAttempt", res.data.data)
        .then(() => {
          this.$router.replace({
            name: "exams-attempt-result",
            params: { id: this.$route.params.id },
          });
        });
    },
    isDateSameOrAfter(value) {
      var value = moment(value, "YYYY-MM-DD hh:mm:ss").add(1, "d");
      var today = moment().format("YYYY-MM-DD hh:mm:ss");
      return moment(value).isSameOrAfter(today);
    },
  },
  async mounted() {
    this.info = await Device.getInfo();
    if (!this.adStatus != "showed") {
      if (this.info && this.info.platform != "web") {
        await interstitial(this);
      }
    }
  },
  created() {
    bus.$emit("showWait");
    this.loading = true;
    this.$store.dispatch("app/exams/resetMocktest");

    this.axiosGetMeta();
    let _id = this.$route.params.id;
    axios
      .get(`prep/attempts/attempted/${_id}`)
      .then((res) => {
        return this.saveAttemptRedirect(res);
      })
      .catch((err) => {
        // this test have not been attempted yet
        setTimeout(() => {
          if (this.mocktest && this.mocktest.id) {
            if (this.isDateSameOrAfter(this.mocktest.saved_at)) {
              console.log("mocktest saved_at is same, using from localstorage");
              return;
            }
          }
          console.log("mocktest saved_at has expired, fetching from server..");
          this.axiosMocktest();
        }, 600);
      })
      .finally(() => {
        setTimeout(() => {
          this.btnCalc();
          this.loading = false;
          bus.$emit("hideWait");
        }, 1000);
      });
  },
};
</script>


