var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.adStatus == 'shown' || 'failed')?_c('div',[(_vm.mocktest && _vm.mocktest.id)?_c('v-row',{staticClass:"text-center justify-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',{staticClass:"blue accent-3 white--text"},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('span',[_vm._v(" "+_vm._s(_vm.mocktest.title)+" ")])],1)],1),_c('v-card',{staticClass:"mb-2"},[_c('v-card-actions',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-format-list-numbered")]),_vm._v(" "+_vm._s(_vm.$lang("Questions"))+" "),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.mocktest.segments.reduce(function (pre, cur) { return pre + cur.questions.length; }, 0))+" ")],1),_c('v-card-actions',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(_vm.$lang("Duration"))+" "),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.calculateDuration(_vm.mocktest))+" ")],1),_c('v-card-actions',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-circle")]),_vm._v(" "+_vm._s(_vm.$lang("Created_By"))+" "),_c('v-spacer'),_c('span',{staticStyle:{"margin-left":"-0.4rem"},attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                name: 'profile',
                params: {
                  id: _vm.mocktest.user
                    ? _vm.mocktest.user.username
                    : _vm.mocktest.user_id,
                },
              })}}},[_vm._v(" "+_vm._s(_vm.mocktest.user ? _vm.mocktest.user.display_name.toUpperCase() : _vm.mocktest.user_id)+" ")])],1),(_vm.mocktest.showDate)?_c('v-card-actions',[_vm._v(" "+_vm._s(_vm.$lang("Date"))+" "),_c('v-spacer'),_vm._v(" "+_vm._s(_vm._f("formatDateOnly")(_vm.mocktest.updated_at))+" ")],1):_vm._e()],1),(_vm.mocktest.description && _vm.mocktest.description.length > 10)?_c('v-card',[_c('v-card-text',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$lang("DIRECTIONS"))+": ")]),_c('v-divider'),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.mocktest.description)}})],1):_vm._e(),_c('v-card',{staticClass:"mt-2"},[_c('v-card-text',{staticClass:"text-left",domProps:{"innerHTML":_vm._s(_vm.directions)}})],1),_c('v-card',{staticClass:"mt-2"},[_c('v-card-actions',[_c('select-test-language')],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"ripple":""}},[_c('v-card-actions',[_c('v-checkbox',{model:{value:(_vm.agree),callback:function ($$v) {_vm.agree=$$v},expression:"agree"}}),_c('v-card-text',{staticClass:"text-left",staticStyle:{"cursor":"pointer"},domProps:{"innerHTML":_vm._s(_vm.disclaimer)},on:{"click":function($event){_vm.agree = !_vm.agree}}})],1),_c('v-card-actions',[_c('v-btn',{class:_vm.mainBtn.color + ' darken-3',attrs:{"disabled":_vm.loading || !_vm.agree,"loading":_vm.loading,"text":"","block":"","dark":""},on:{"click":function($event){return _vm.$router.push({
                name: 'exams-mocktest',
                params: { id: _vm.mocktest.id },
              })}}},[(!_vm.agree)?_c('span',[_vm._v(" "+_vm._s(_vm.isHindi ? "पहले ऊपर दी हुई शर्तों को स्वीकार करें" : "Accept the terms above.")+" ")]):_c('span',[_vm._v(_vm._s(_vm.$lang(_vm.mainBtn.text)))]),(_vm.mainBtn.time && _vm.agree)?_c('span',[_vm._v(" ("+_vm._s(_vm.mainBtn.time.toString().fancyTime())+") ")]):_vm._e(),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }